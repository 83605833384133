import React, {useState} from 'react';
import styles from './inheritance-tax-info.module.scss';
import classNames from 'classnames/bind';
import {Form, Formik, FormikValues} from 'formik';
import {InputBox, mdmRelList, NumberInputBox, SelectInputBox} from './customer/edit-common';
import {useInjection} from 'inversify-react';
import {useMountedState} from 'react-use';
import {ReportAddInfoPopProps} from './report-add-info-pop';
import {asyncCall} from '../util/util';
import {toast} from 'react-toastify';
import {strSaved} from '../data/strings';
import {DialogManager} from '../components/dialog-manager';
import {CustomerDetailTab} from '../data/const_list';
import {getAge0, numberOnly, toBirthAndSex} from '../util/str-util';
import {CustomerInputApi} from "../model/customer/customer-input-api";
import {useCustomerInputClear} from "../model/customer/customer-input-hook";
import CustomerDetailPop from './customer/customer-detail-pop';
import { CoReportApi } from '../model/co-report/co-report-api';

const cx = classNames.bind(styles);

const InheritanceTaxInfo = ({data, setData, onClose, secondTab}: ReportAddInfoPopProps) => {
    const customerInputApi = useInjection(CustomerInputApi);
    const reportApi = useInjection(CoReportApi);
    const dialogManager = useInjection(DialogManager);
    const isMounted = useMountedState();
    const [tab, setTab] = useState(secondTab === 'spouse' ? false : true);
    const [ancestorAge, setAncestorAge] = useState(false);
    const {clearInfo} = useCustomerInputClear(setData);

    const customer = data.customer;
    if (!customer) return null;
    const giverList = [{id: "", title: "선택"}, ...customer.shareholder.list?.map(it => ({id: it.STH_NM, title: it.STH_NM})) ?? []];
    const inherit = customer.inhr;
    const spouseCheck = [{id: true, title: '있음'}, {id: false, title: '없음'}];
    const field = 'list.' + (tab ? 0 : 1) + '.';

    const onChangeAncestor = (id: string, setFieldValue: (field: string, value: any, shouldValidate?: (boolean | undefined)) => void) => {
        setFieldValue('ancestor', id);

        if (data.customer.mng.list) {
            const mng = data.customer.mng.list.find((m) => m.NAME === id);

            if (mng) {
                setFieldValue('relation', mng.MDM_REL_CD);

                if(mng.PID) {
                    const [birth] = toBirthAndSex(mng.PID);
                    if (birth)
                        setFieldValue(field + 'age', getAge0(birth, new Date()));
                }
                else {
                    setFieldValue(field + 'age', "");
                }
            }
            else {
                setFieldValue('relation', "");
                setFieldValue(field + 'age', "");
            }
        }
    }

    const moveTab = (newTabVal: boolean, values: FormikValues) => {
        if (!newTabVal) {
            if (!values['spouse']) {

                return;
            }
        }
        setTab(newTabVal);
    }

    return (
        <Formik
            initialValues={inherit}
            onSubmit={(values) => {
                if(values.list[0].age === undefined || Number(values.list[0].age) <= 0) {
                    setAncestorAge(true);
                    dialogManager.alert('피상속인 나이를 입력해 주세요.');
                } else {
                    setAncestorAge(false);
                    asyncCall(customerInputApi.saveInheritanceInfo, [customer.id, values], result => {
                        setData(result);
                        data.customer = result.customer;//팝업에서 사용하는 데이터 업데이트
                        toast.success(strSaved);
                    }, isMounted, () => {
                        dialogManager.alert("입력값에 문제가 발생하여 리포트 생성에 오류가 발생하였습니다. 값을 다시 확인해 주세요.");
                    });
                }
            }}>
            {({values, setFieldValue, resetForm}) =>
                <Form className={cx('inheritance-tax-info')}>
                    <div className={cx('btn-area')}>
                        {/* <button className='close-btn' type='button' onClick={() => clearInfo(customer.id, 'inheritance',
                         (report) => resetForm({values: report.customer.inhr})
                     )}><i></i>초기화</button> */}
                        <button className={cx('save-btn')} type='submit'><i></i>저장하기</button>
                        <button className='close-btn' type='button' onClick={() => onClose()}>닫기</button>
                    </div>
                    <div className={cx('scroll-box')}>
                        <div className={cx('scroll-area')}>
                            <p className={cx('title')}>상속세 계산 정보<button className={cx('reset-btn')} type='button' onClick={() => clearInfo(customer.id, 'inheritance',
                                (report) => resetForm({values: report.customer.inhr})
                            )}><i></i>초기화</button></p>
                            <div className={cx('tab-area')}>
                                <button type='button' className={cx(tab && 'on')} onClick={() => moveTab(true, values)}>고객 상속
                                </button>
                                <button type='button' className={cx(!tab && 'on')} onClick={() => moveTab(false, values)}>배우자 2차 상속
                                </button>
                            </div>
                            <div className={cx('inheritance-box')}>
                                <div className={cx('grid-box', 'box1')}>
                                    {tab ? <SelectInputBox title='피상속인' name='ancestor' selectList={giverList}
                                                           inputWidth='20rem'
                                                           inputboxCn={cx('input-box')}
                                                           onChange={(id) => onChangeAncestor(id, setFieldValue)}/>
                                        : <NumberInputBox title='고객상속후 배우자 상속개시' name='inheritance_sp' unit='년 후'
                                                          inputWidth='20rem'
                                                          inputboxCn={cx('input-box')}/>
                                    }

                                    {tab ? <SelectInputBox title='경영실권자와의 관계' name='relation' selectList={mdmRelList}
                                                           inputWidth='20rem'
                                                           inputboxCn={cx('input-box')}/>
                                        : <NumberInputBox title='성년 자녀' name='maturechild' unit='명' inputWidth='20rem'
                                                          inputboxCn={cx('input-box')}/>
                                    }

                                    {tab ? <NumberInputBox title='피상속인 나이' name={field + 'age'} unit='세' placeholder='입력'
                                                           inputWidth='20rem' onChange={(e) => {
                                            setFieldValue(field + 'age', numberOnly(e.target.value));
                                            e.target.value.length > 0 && setAncestorAge(false);
                                        }}
                                                           inputboxCn={cx('input-box', ancestorAge ? 'error' : '')}/>
                                        : <NumberInputBox title='배우자 나이' name={field + 'age'} unit='세' placeholder='입력'
                                                          inputWidth='20rem'
                                                          inputboxCn={cx('input-box')}/>}
                                    {tab && <SelectInputBox title='배우자 유무' name='spouse' selectList={spouseCheck}
                                                            inputWidth='20rem'
                                                            inputboxCn={cx('input-box')}/>}

                                    {tab && <NumberInputBox title='성년 자녀' name='maturechild' unit='명' inputWidth='20rem'
                                                            inputboxCn={cx('input-box')}/>}

                                    <NumberInputBox title='미성년 자녀' name='immaturechild' unit='명' inputWidth='20rem'
                                                    inputboxCn={cx('input-box')}/>
                                </div>
                                <p className={cx('sub-title')}>1. {!tab && '배우자'} 상속자산 및 채무 가액을 입력해 주세요.</p>
                                <div className={cx('grid-box')}>
                                    <NumberInputBox title='금융자산' name={field + 'finasset'} unit='원' inputWidth='20rem'
                                                    inputboxCn={cx('input-box')}/>
                                    <NumberInputBox title='금융기관 대출' name={field + 'loan'} unit='원' placeholder='입력'
                                                    inputWidth='20rem'
                                                    inputboxCn={cx('input-box')}/>
                                    <NumberInputBox title='부동산자산(감정평가액)' name={field + 'realestate'} unit='원'
                                                    placeholder='입력'
                                                    inputWidth='20rem' inputboxCn={cx('input-box')}/>
                                    <NumberInputBox title='부동산 임대보증금' name={field + 'depositdebt'} unit='원' placeholder='입력'
                                                    inputWidth='20rem' inputboxCn={cx('input-box')}/>
                                    <InputBox title='금융자산 상승률(%)' name='finassetinc' type='number' unit='%' inputWidth='20rem'
                                              inputboxCn={cx('input-box')}/>
                                    <NumberInputBox title='기타 채무' name={field + 'otherdebt'} unit='원' placeholder='입력'
                                                    inputWidth='20rem'
                                                    inputboxCn={cx('input-box')}/>
                                    <InputBox title='부동산자산 상승률(%)' name='realestateinc' type='number' unit='%'
                                              inputWidth='20rem'
                                              inputboxCn={cx('input-box')}/>
                                    <NumberInputBox title='장례비용' name='funeralcost' unit='원' inputWidth='20rem'
                                                    inputboxCn={cx('input-box')}/>
                                    {!tab && <InputBox title='재상속재산 상승률(%)' name='inheritassetinc' type='number' unit='%'
                                                       inputWidth='20rem'
                                                       inputboxCn={cx('input-box')}/>}
                                </div>
                                <p className={cx('foot-note')}>
                                    {tab ?
                                        '※ 피상속인이 보유한 가업주식자산은 상속자산에 자동 반영됩니다.'
                                        : <span>※ <span
                                            className={cx('move-page-txt')}
                                            onClick={() => {
                                                //  navigate('/customer/customerState', {
                                                //      state: {
                                                //          type: 'addInfoPop',
                                                //          customerId: data.customer.id,
                                                //          tab: CustomerDetailTab.Member,
                                                //          edit: true,
                                                //      }
                                                //  });

                                                dialogManager.open(CustomerDetailPop, {
                                                    customerId: data.customer.id,
                                                    tab: CustomerDetailTab.Member,
                                                    edit: true,
                                                    onDialogClose: async () => {
                                                        const updateResult = await reportApi.coReportV2(data.customer.id!);
                                                        if (updateResult.val) {
                                                            setData!(updateResult.val!);
                                                        }
                                                    }
                                                });
                                            }}
                                        >주주현황</span>에서 경영실권자와의 관계를 배우자로 선택해야, 배우자가 보유한 가업주식자산이 상속자산에 자동 반영됩니다.</span>
                                    }
                                </p>
                                <p className={cx('sub-title')}>2. {!tab && '배우자'} 10년(5년) 이내 증여재산 내역을 입력해 주세요.</p>
                                <div className={cx('grid-box')}>
                                    <NumberInputBox title='기증여재산' name={field + 'giftasset'} unit='원' placeholder='입력'
                                                    inputWidth='20rem'
                                                    inputboxCn={cx('input-box')}/>
                                    <NumberInputBox title='기납부증여세' name={field + 'gifttax'} unit='원' placeholder='입력'
                                                    inputWidth='20rem'
                                                    inputboxCn={cx('input-box')}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </Form>
            }
        </Formik>
    );
};

export default InheritanceTaxInfo;