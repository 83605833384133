import React, {useRef, useState} from 'react';
import styles from './edit-consult-log.module.scss';
import classnames from 'classnames/bind';
import {Field, FieldArray, Form, Formik} from 'formik';
import SelectButton from '../../components/select-button';
import ReactTextareaAutosize from 'react-textarea-autosize';
import {ClosableProps} from '../../components/dialog';
import FormikField from '../../components/formik-field';
import * as Yup from 'yup';
import {useInjection} from 'inversify-react';
import {CustomerApi} from '../../model/customer/customer-api';
import {useMountedState} from 'react-use';
import {asyncCall} from '../../util/util';
import {toast} from 'react-toastify';
import {CounselingStage} from '../../model/customer/dto/counseling-stage';
import {CounselingLog} from '../../model/customer/dto/counseling-log';
import FormikErrorMsg from '../../components/formik-error-msg';
import {strSaved} from '../../data/strings';
import {DatePicker} from './edit-common';

const cx = classnames.bind(styles);

interface EditStageListItemProps {
    data: CounselingStage[];
    setData: (list: CounselingStage[]) => void;
    closePop: () => void;
}

const EditStageListItem = ({data, setData, closePop}: EditStageListItemProps) => {
    const customerApi = useInjection(CustomerApi);
    const isMounted = useMountedState();

    return (
        <div className={cx('edit-stage-list-pop')}>
            <p className={cx('stage-list-title')}>상담단계 수정</p>
            <Formik
                initialValues={{stages: [...data]} /* mobx proxy 때문에 data를 그냥 쓰면 추가하기에서 에러 발생 */}
                validationSchema={Yup.object({
                    stages: Yup.array().of(
                        Yup.object({
                            stage: Yup.string().required('상담단계를 입력해주세요.')
                        }))
                })}
                onSubmit={values => {
                    asyncCall(customerApi.saveCounselingStage, [ values.stages], result => {
                        setData(result);
                        closePop();
                        toast.success(strSaved);
                    }, isMounted);
                }}
            >
                {({values}) => (
                    <Form>
                        <FieldArray name='stages'>
                            {({remove, push}) => (
                                <ul className={cx('stage-input-list')}>
                                    {values.stages.map((li, i) => (
                                        <li key={i}>
                                            <FormikField name={`stages.${i}.stage`} type='text' maxLength={10}/>
                                            <button className={cx('delete-btn')} type='button'
                                                    onClick={() => remove(i)}></button>
                                        </li>
                                    ))}
                                    <li>
                                        <button type='button' onClick={() => push({stage: ''})}>+ 추가하기</button>
                                    </li>
                                </ul>
                            )}
                        </FieldArray>
                        <div className={cx('btn-area')}>
                            <button className={cx('close-btn')} type='button' onClick={closePop}>닫기</button>
                            <button className={cx('save-btn')} type='submit'><i></i>저장하기</button>
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    );
};

interface EditConsultLogPopProps extends ClosableProps {
    customerId: string;
    counselingLog: CounselingLog;
    stages: CounselingStage[];
    refresh: () => Promise<void>;
}

const EditConsultLogPop = ({customerId, counselingLog, stages, refresh, onClose}: EditConsultLogPopProps) => {
    const customerApi = useInjection(CustomerApi);
    const [stageList, setStageList] = useState(stages);
    const [showPopup, setShowPopup] = useState(false);
    const inputRef = useRef<HTMLInputElement>(null);

    const stageList2 = stageList.map(it => ({id: it.no, title: it.stage}));
    const getStage = (stageNo?: number) => stageList.find(it => it.no === stageNo)?.stage ?? '';

    if (counselingLog.stage && !getStage(counselingLog.stageNo)) {
        counselingLog.stage = ''; //상담단계가 삭제된 경우 처리
    }

    return (
        <div className={`${cx('edit-consult-log')} full-background`}>
            <div className={`pop-inner ${cx('edit-dialog')}`}>
                <Formik
                    initialValues={counselingLog}
                    validationSchema={Yup.object({
                        date: Yup.string().required('날짜를 입력해주세요.'),
                        stageNo: Yup.number().required('상담단계를 입력해주세요.'),
                        content: Yup.string().required('내용을 입력해주세요.')
                    })}
                    onSubmit={async values => {
                        await customerApi.saveCounselingLog(customerId, values);
                        await refresh();
                        toast.success(strSaved);
                        onClose();
                    }}>
                    {({values, setFieldValue, getFieldProps, setFieldTouched}) =>
                        <Form>
                            <p className={cx('title')}>상담차수 &gt; {values.round}차</p>
                            <div className={cx('input-box', 'date-box')}>
                                <label>상담일지</label>
                                <DatePicker name='date'/>
                            </div>
                            <div className={cx('input-box', 'stage-box')}>
                                <label>상담단계</label>
                                <div className={cx('inner-input')}>
                                    <SelectButton
                                        listWidth='41.2rem'
                                        cn={['border-bottom']}
                                        title='단계선택'
                                        data={stageList2}
                                        addList={() => setShowPopup(true)}
                                        field='stageNo'
                                        addBtnText='수정'
                                        setFieldValue={setFieldValue}
                                        onShowMenu={() => {
                                            inputRef.current?.blur(); //메뉴를 선택할 때 blur되면서 에러 메시지 나오는 것 방지
                                            setFieldTouched('stageNo', false);
                                        }}>
                                        <Field
                                            name='stage'
                                            type='text'
                                            placeholder='상담단계'
                                            value={getStage(values.stageNo)}
                                            readOnly
                                            innerRef={inputRef}/>
                                    </SelectButton>
                                    <FormikErrorMsg name='stage'/>
                                </div>
                            </div>
                            <div className={cx('input-box')}>
                                <label>상담내용</label>
                                <div className={cx('inner-input')}>
                                    <ReactTextareaAutosize maxLength={500} maxRows={29} placeholder='내용을 입력해 주세요.'
                                                           {...getFieldProps('content')}/>
                                    <FormikErrorMsg name='content'/>
                                </div>
                            </div>
                            <div className={cx('btn-area')}>
                                <button className={cx('close-btn')} type='button' onClick={() => onClose()}>닫기</button>
                                <button className={cx('save-btn')} type='submit'><i></i>저장하기</button>
                            </div>
                        </Form>}
                </Formik>
                {showPopup &&
                    <EditStageListItem data={stageList} setData={setStageList} closePop={() => setShowPopup(false)}/>}
            </div>
        </div>
    );
};

export default EditConsultLogPop;