import styles from './business-type-pop.module.scss';
import classNames from 'classnames/bind';
import {Field, Form, Formik} from 'formik';
import {forwardRef, useEffect, useRef} from 'react';
import {ClosableProps} from './dialog';
import {useInit} from '../util/util';
import {useInjection} from 'inversify-react';
import {CustomerApi} from '../model/customer/customer-api';
import {IndustryCls, OneCode} from '../model/customer/dto/industry-cls';

const cx = classNames.bind(styles);
interface TypeBoxProps {
    industry: IndustryCls[]
    values: {mainField: string, middleTypeOption: string, smallTypeOption: string }
    setFieldValue: (field: string, value: any, validate?: boolean) => void
}

interface FieldBoxProps {
    id: string
    name: string
    value: string
    labelValue?: string
    field: string
    onClick?: () => void
}

const FieldBox = forwardRef<HTMLDivElement, FieldBoxProps>(({id, name, value, labelValue, field, onClick}:FieldBoxProps, ref) => {

    const currentRef = field === value ? ref : undefined;

    return (
        <div ref={currentRef} className={cx('field')}>
            <Field id={id} type='radio' name={name} value={value}/>
            <label htmlFor={id} onClick={onClick} title={labelValue ?? value}>{labelValue ?? value}</label>
        </div>
    );
});

const TypeBox = ({industry, values, setFieldValue}: TypeBoxProps) => {
    const industryClsSub = industry.find((indus) => indus.name === values.mainField)?.clsSubs;
    const smallCls = industryClsSub?.find((mid) => mid.name === values.middleTypeOption)?.smallCls;
    const selectedClsBig = useRef<HTMLDivElement | null>(null);
    const selectedClsMiddle = useRef<HTMLDivElement | null>(null);
    const selectedClsSmall = useRef<HTMLDivElement | null>(null);

    const onRef = (ref: React.RefObject<HTMLDivElement | null>) => {
        if (ref !== null && ref) {
            ref.current?.scrollIntoView({block: 'center'});
        }   
    }

    useEffect(() => {
        onRef(selectedClsBig);
        onRef(selectedClsMiddle);
        onRef(selectedClsSmall);
    }, [selectedClsBig, selectedClsMiddle, selectedClsSmall]);

    const resetValues = () => {
        setFieldValue('middleTypeOption', '');
        setFieldValue('smallTypeOption', '');
    };

    return(
        <div className={cx('type-box')}>
            <div className={cx('field-box', 'scroll', values.mainField && 'gray-txt')}>
            {industry.map((field) => {
                return <FieldBox 
                    ref={selectedClsBig}
                    key={field.id} 
                    id={field.code} 
                    name='mainField' 
                    value={field.name} 
                    field={values.mainField}
                    onClick={resetValues}/>;
            })}
            </div>
            {!industryClsSub && <p className={cx('info-line')}><span>←</span>대분류부터 선택하세요.</p>}
            { industryClsSub &&
                <div className={cx('field-box', 'scroll', values.middleTypeOption && 'gray-txt')}>
                { industryClsSub.map((middle) => {
                    return <FieldBox
                        ref={selectedClsMiddle}
                        key={middle.code} 
                        id={middle.code} 
                        name='middleTypeOption' 
                        value={middle.name} 
                        field={values.middleTypeOption}
                        onClick={()=>setFieldValue('smallTypeOption','')}/>;
                })}
                </div>}
            {smallCls &&
                <div className={cx('field-box', 'scroll', values.smallTypeOption && 'gray-txt')}>
                { smallCls.map((small) => {
                    return <FieldBox 
                        ref={selectedClsSmall}
                        key={small.code} 
                        id={small.code} 
                        name='smallTypeOption' 
                        value={small.code}
                        labelValue={small.str}
                        field={values.smallTypeOption}/>;
                })}
                </div>}
            {industryClsSub && !smallCls && <p className={cx('info-line')}><span>←</span>중분류부터 선택하세요.</p>}
        </div>
    );
};

interface BusinessTypePopProps extends ClosableProps {
    code: string;
    setBusinessField: (code: string, name: string) => void;
}

const BusinessTypePop = ({code, setBusinessField, onClose}: BusinessTypePopProps) => {
    const customerApi = useInjection(CustomerApi);
    const [industry] = useInit(customerApi.getIndustryCls, [], []);

    const findCode = (code: string) => {
        let main = '';
        let middle = '';
        let small: OneCode | undefined;
        for (const mainObj of industry) {
            for (const middleObj of mainObj.clsSubs) {
                small = middleObj.smallCls.find(it => it.code === code);
                if (small) {
                    middle = middleObj.name;
                    break;
                }
            }
            if (small) {
                main = mainObj.name;
                break;
            }
        }
        return small ? [main, middle, small.str, small.code] : ['', '', '', ''];
    }

    const data = findCode(code);

    const autoH = window.innerWidth < 1320 && window.innerHeight < 691;

    return (
        <div className='full-background'>
            {industry.length > 0 && <Formik
                initialValues={{
                    mainField: data[0],
                    middleTypeOption: data[1],
                    smallTypeOption: data[3] || ''
                }}
                onSubmit={(values) => {
                    const data = findCode(values.smallTypeOption);
                    setBusinessField(data[3], data[2]);
                    onClose();
                }}>
                {({values, setFieldValue}) =>
                    <div className={`pop-inner ${cx('business-type-pop')} ${autoH ? cx('innerHVer2') : ''}`}>
                        <Form>
                            <p className={cx('title')}>업종을 선택해 주세요.</p>
                            <div className={cx('type-list')}>
                                <TypeBox
                                    industry={industry}
                                    values={values}
                                    setFieldValue={setFieldValue}/>
                            </div>
                            <div className={cx('btn-area')}>
                                <button type='button' className='close-btn' onClick={onClose}>닫기</button>
                                <button type='submit' className={cx('save-btn')}
                                        disabled={!values.smallTypeOption}><i></i>선택
                                </button>
                            </div>
                        </Form>
                    </div>
                }
            </Formik>}
        </div>
    );
};

export default BusinessTypePop;