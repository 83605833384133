import {toast} from 'react-toastify';
import {DialogManager} from '../components/dialog-manager';

export const regPhone = /^01([016789])-?([0-9]{3,4})-?([0-9]{4})$/;
export const idRule = /^[a-z]+[a-z0-9]{5,11}$/;
export const passwordRule = /^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,12}$/;
export const emailRule = /^[a-zA-Z0-9+-_.]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;

export function GetAuthNum(values: { [key: string]: string }, dialogManager: DialogManager, setDisabled?: (b: boolean) => void) {
    // var regPhone = /^01([016789])-?([0-9]{3,4})-?([0-9]{4})$/;

    if (values.userId === '') {
        dialogManager.alert('아이디를 입력해 주세요.');
    } else if (values.username === '') {
        dialogManager.alert('이름을 입력해 주세요.');
    } else if (values.phone === '') {
        dialogManager.alert('휴대폰 번호를 입력해 주세요.');
    } else if (!regPhone.test(values.phone)) {
        dialogManager.alert('휴대폰 번호를 다시 확인해 주세요.');
    } else {
        setDisabled?.(true);
        toast.success('인증번호가 전송되었습니다.');
    }
}

export function ChkAuthNum(values: { [key: string]: string }, dialogManager: DialogManager, moveNext: () => void) {
    if (values.authNum === '') {
        dialogManager.alert('인증번호를 입력해 주세요.');
    } else if (values.authNum === '1') {
        dialogManager.alert('인증번호를 잘못 입력하였습니다. 다시 확인해 주세요.');
    } else {
        toast.success('인증이 완료되었습니다.');
        setTimeout(() => {
            moveNext();
        }, 2000);
    }
}

export function LimitTxt(name: string, value: string) {
    if (name === 'userId') {
        return value.replace(/[^a-z0-9]/g, ''); // 영문, 숫자만 입력 가능
    } else if (name === 'username') {
        return value.replace(/[^ㄱ-ㅎ|가-힣]/g, ''); // 한글만 입력 가능
    } else {
        return value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1'); // 숫자만 입력 가능
    }
}