import React, {ReactNode, useState} from 'react';
import styles from './personal-data.module.scss';
import classNames from 'classnames/bind';
import TitleBox from '../../components/title-box';
import {EMPTY, formatPhoneNum, getAge, toDateString, ymdToDate, ymdToDateString} from '../../util/str-util';
import EditCeoInfo from './edit-ceo-info';
import EditKeyManInfo from './edit-keyman-info';
import NoInfo from '../../components/no-info';
import {CustomerDetail} from '../../model/customer/dto/customer-detail';
import {FamInfo, RepInfo} from '../../model/customer/dto/rep-info';
import EditMnaPartnerInfo from "./edit-mna-partner-info";

const cx = classNames.bind(styles);

interface CeoInfoProps {
    title: string
    desc?: string | ReactNode
    list?: FamInfo[]
}

const PersonalData = ({data, setData, repInfo, isAdvisor, cusDetailPopClose}
                          : { data: CustomerDetail, setData: React.Dispatch<React.SetStateAction<CustomerDetail>>, repInfo: RepInfo, isAdvisor: boolean, cusDetailPopClose: VoidFunction}) => {
    const [editCeoInfo, setEditCeoInfo] = useState<RepInfo | null>(repInfo ?? null);
    const [showEditKeyManInfo, setShowEditKeyManInfo] = useState(false);
    const [editMnaPartnerInfo, setEditMnaPartnerInfo] = useState(false);

    const openEditKeyManInfoPop = () => {
        setShowEditKeyManInfo(!showEditKeyManInfo);
    };

    const openEditMnaPartnerInfoPop = () => {
        setEditMnaPartnerInfo(!editMnaPartnerInfo);
    }


    const customer = data.customer;
    if (!customer) return null;

    // 대표 이사 이름 여부 체크 때문에 기존 정보를 사용한다.
    const orgInfo = data.summary?.REP_INFO.find(it => it.ID === editCeoInfo?.pcd);

    const noInfo = <span style={{fontSize: '1.3rem', color: '#999'}}>등록된 정보가 없습니다.</span>;
    const rep = customer.rep.length > 0 ? customer.rep : [{} as RepInfo];
    const keyman = customer.keyman;
    const hasKeyman = keyman && (keyman.name || keyman.rel || keyman.phone || keyman.remark);
    const hasPartnerInfo = true;

    return (
        <div className={cx('personal-data')}>
            <div className={cx('table')}>
                {rep.map((pi, i) => {
                    const birth = ymdToDate(pi.birthday);

                    const ceoInfoList:CeoInfoProps[][] = [
                        [
                            {title: '대표이사명', desc: pi.name ?? noInfo},
                            {title: '성별', desc: pi.female != null ? (pi.female ? '여자' : '남자') : EMPTY}
                        ],
                        [
                            {
                                title: '생년월일(나이)',
                                desc: birth ? toDateString(birth) + ' (' + getAge(birth) + ')' : noInfo
                            },
                            {
                                title: '실제생일',
                                desc: pi.realBirth ? ymdToDateString(pi.realBirth) + (pi.lunar ? ' (음력)' : '') : noInfo
                            }
                        ],
                        [
                            {title: '전화번호', desc: pi.phone ? formatPhoneNum(pi.phone) : noInfo},
                            {title: '이메일', desc: pi.email ? pi.email : noInfo}
                        ],
                        [
                            {title: '경력 및 학력사항', desc: pi.career ? pi.career : noInfo},
                            {title: '특이사항', desc: pi.remark ? pi.remark : noInfo}
                        ],
                        [
                            {
                                title: '가족 정보',
                                list: pi.fam,
                            }
                        ]
                    ];

                    return <React.Fragment key={i}>
                        <TitleBox title='대표이사 정보' paddingTop='4.0rem'>
                            <button className={cx('edit-btn')} onClick={() => setEditCeoInfo(pi)}><i></i><span>수정</span></button>
                        </TitleBox>
                        {ceoInfoList.map((li) =>
                            <div className={cx('line-box')} key={li[0].title}>
                                {li.map((li2) => li2.desc ?
                                    <div className={cx('one-set')} key={li2.title}>
                                        <span className={cx('title')}>{li2.title}</span>
                                        <pre className={cx('desc')}>{li2.desc}</pre>
                                    </div>
                                    :
                                    <div className={cx('one-set', 'two')} key={li2.title}>
                                        <span className={cx('title')}>{li2.title}</span>
                                        <div className={cx('fam')}>
                                            {
                                                li2.list ?
                                                    li2.list?.map((it, i) =>
                                                        <div key={i} className={cx('fam-list')}>
                                                            <span>{it.relation}</span>
                                                            <span>{it.name}</span>
                                                            <span>
                                                                {
                                                                    it.birthday ? `${ymdToDateString(it.birthday)} (${getAge(new Date(ymdToDateString(it.birthday)))})` : "생년월일 정보 없음"
                                                                }
                                                            </span>
                                                            <span>{it.note}</span>
                                                        </div>
                                                    )
                                                    : noInfo
                                            }
                                        </div>
                                    </div>
                                )}
                            </div>
                        )}
                    </React.Fragment>;
                })}
                {editCeoInfo &&
                    <EditCeoInfo customerId={customer.id} personInfo={editCeoInfo} setData={setData} orgInfo={orgInfo} hasTwoDialog={!!repInfo}
                                 cusDetailPopClose={cusDetailPopClose}
                                 onClose={() => setEditCeoInfo(null)}/>}
            </div>
            {
                isAdvisor && <>
                    <TitleBox title='소개파트너 정보'>
                        <button className={cx('edit-btn')} onClick={openEditMnaPartnerInfoPop}><i></i><span>수정</span></button>
                    </TitleBox>
                    {hasPartnerInfo ? <div className={cx('table')}>
                            <div className={cx('line-box')}>
                                <div className={cx('one-set')}>
                                    <span className={cx('title')}>이름</span>
                                    <pre className={cx('desc')}>홍길동 FP / M&A파트너</pre>
                                </div>
                                <div className={cx('one-set')}>
                                    <span className={cx('title')}>소속</span>
                                    <pre className={cx('desc')}>삼성생명GFC</pre>
                                </div>
                            </div>
                            <div className={cx('line-box')}>
                                <div className={cx('one-set')}>
                                    <span className={cx('title')}>전화번호</span>
                                    <pre className={cx('desc')}>{formatPhoneNum('01099131436')}</pre>

                                </div>
                                <div className={cx('one-set')}>
                                    <span className={cx('title')}>이메일</span>
                                    <pre className={cx('desc')}>srpark1436@gmail.com</pre>
                                </div>
                            </div>
                            <div className={cx('line-box')}>
                                <div className={cx('one-set')}>
                                    <span className={cx('title')}>특이사항</span>
                                    <pre className={cx('desc')}>삼성생명 MDRT 회장, COT</pre>
                                </div>
                            </div>
                        </div> :
                        <NoInfo/>}
                </>
            }
            {editMnaPartnerInfo && <EditMnaPartnerInfo customerId={customer.id} setData={setData} onClose={openEditMnaPartnerInfoPop}/>}

            <TitleBox title='키맨 정보'>
                <button className={cx('edit-btn')} onClick={openEditKeyManInfoPop}><i></i><span>수정</span></button>
            </TitleBox>
            {hasKeyman ? <div className={cx('table')}>
                    <div className={cx('line-box')}>
                        <div className={cx('one-set')}>
                            <span className={cx('title')}>이름</span>
                            <pre className={cx('desc')}>{keyman.name}</pre>
                        </div>
                        <div className={cx('one-set')}>
                            <span className={cx('title')}>관계</span>
                            {keyman.rel.length === 0 ?
                                noInfo : <pre className={cx('desc')}>{keyman.rel}</pre>
                            }
                        </div>
                    </div>
                    <div className={cx('line-box')}>
                        <div className={cx('one-set')}>
                            <span className={cx('title')}>전화번호</span>
                            {keyman.phone.length === 0 ?
                                noInfo : <pre className={cx('desc')}>{formatPhoneNum(keyman.phone)}</pre>
                            }
                        </div>
                        <div className={cx('one-set')}>
                            <span className={cx('title')}>특이사항</span>
                            {keyman.remark.length === 0 ?
                                noInfo : <pre className={cx('desc')}>{keyman.remark}</pre>
                            }
                        </div>
                    </div>
                </div> :
                <NoInfo/>}
            {showEditKeyManInfo && <EditKeyManInfo customerId={customer.id} keyman={keyman} setData={setData}
                                                   onClose={openEditKeyManInfoPop}/>}
        </div>
    );
};

export default PersonalData;