import {PrEtcApi} from "../model/etc/pr-etc-api";
import {DialogManager} from "../components/dialog-manager";

export const isAvailableCorpReg = async (prEtcApi: PrEtcApi, dialogManager: DialogManager) => {
    /**
     * 관리자에서 등기조회 생략이 ON인 경우
     */
    const corpReg = await prEtcApi.getCorpReg();

    const flag = corpReg === "Y";

    if (flag) {
        dialogManager.alert(`법인등기부등본 조회 서비스 점검 중`, `대법원인터넷등기소 점검이슈로 현재 등기 조회가 불가합니다.`);
        return false;
    }

    /**
     * 등기부등본 점검 시간대에 해당하는 경우
     */
    const maintenance = await prEtcApi.getCorpRegMaintenance();

    if (maintenance.underMaintenance) {
        dialogManager.alert(`법인등기부등본 조회 서비스 ${maintenance.eventType}`, `${maintenance.description}\n${maintenance.startDate} ~ ${maintenance.endDate}`);
        return false;
    }

    return true;
}