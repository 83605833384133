import styles from './video-pop.module.scss';
import classNames from 'classnames/bind';
import {useEffect, useRef} from "react";
import YouTube from "react-youtube";
import ReactPlayer from "react-player";
import Vimeo from "@u-wave/react-vimeo";

const cx = classNames.bind(styles);

interface VideoPopProps {
    channel: string
    title: string
    url: string
    ratio?: 'HD' | 'SD' // 화면 비율 HD = 16*9 , SD = 4*3
    onClose: VoidFunction
}

const VideoPop = ({channel, title, url, ratio = 'SD', onClose}:VideoPopProps) => {
    const vimeoRef = useRef<Vimeo>(null);

    useEffect(() => {
        if (vimeoRef.current) {
            console.log(vimeoRef.current); // Vimeo DOM 요소에 접근
        }
    }, []);

    return (
        <div className='full-background'>
            <div className={`pop-inner ${cx('video-pop')}`}>
                <div className={cx('video-title')}>
                    <p>{title}</p>
                    <button className={cx('close-btn')} onClick={onClose}></button>
                </div>
                {channel === 'YOUTUBE' && <YouTube videoId={url} className={cx('video-box', ratio === 'HD' ? 'ver2' : '')}/>}
                { channel === 'VIMEO' &&
                    <Vimeo
                        ref={vimeoRef}
                        className={cx('video-box', ratio === 'HD' ? 'ver2' : '')}
                        video={url}
                        showTitle={true}
                        controls={true}
                        speed={true}
                        keyboard={true}
                    />
                }
                {
                    channel === 'SMILE' && <ReactPlayer
                        className={cx(ratio === 'HD' ? 'ver2' : '')}
                        url={url}
                        width={'72rem'}
                        height={'40.5rem'}
                        playing={true}
                        muted={true}
                        controls={true}
                    />
                }
            </div>
        </div>
    );
};

export default VideoPop;