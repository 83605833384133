import {inject, injectable} from 'inversify';
import {ApiCall} from '../api-call';
import {PrVersion} from "./dto/pr-version";
import {PrGroupDto} from "./dto/pr-group-dto";
import {SystemPopup} from "../popup/dto/system-popup";
import {PrSearchMemberDto} from "./dto/pr-search-member-dto";
import {MaintenanceEventDto} from "./dto/maintenance-event-dto";

@injectable()
export class PrEtcApi {
    @inject(ApiCall)
    private apiCall!: ApiCall;

    getVer = async () => {
        return await this.apiCall.fetch<PrVersion>(`/api/etc/ver`);
    }

    getGroup = async (groupId: string) => {
        return await this.apiCall.fetch<PrGroupDto>(`/api/etc/group/${groupId}`);
    }

    getPopup = async () => {
        return await this.apiCall.fetch<SystemPopup[]>(`/api/etc/popup`);
    }

    getPopupByCode = async (code: string) => {
        return await this.apiCall.fetch<SystemPopup>(`/api/etc/popup/${code}`);
    }

    /**
     * 회원을 검색한다.
     */
    searchMember = async (name: string) => {
        return await this.apiCall.fetch<PrSearchMemberDto[]>(`/api/etc/member/search?name=${encodeURIComponent(name)}`);
    }


    /**
     * 등기 조회 여부 설정값 MongoDB 조회 API Call
     */
    getCorpReg = async () => {
        return await this.apiCall.fetch<string>(`/api/etc/prInfo/prCorpReg`);
    }

    /**
     * 코드에프에서 발송한 등기부등본 점검 메일 조회 API Call
     */
    getCorpRegMaintenance = async () => {
        return await this.apiCall.fetch<MaintenanceEventDto>(`/api/etc/check/maintenance/corp`);
    }
}
