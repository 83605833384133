import React, {ButtonHTMLAttributes, ReactNode, useMemo, useRef, useState, JSX} from 'react';
import {PDFDownloadLink} from '@react-pdf/renderer';
import {useFont, usePdfMode} from './pdf-hooks';
import {ChartContainer} from '../../components/chart-container';
import {useInjection} from 'inversify-react';
import {ChartManager} from '../../components/chart-manager';
import {asyncCall} from '../../util/util';
import {useMountedState} from 'react-use';
import {Attachment} from '../../model/customer/dto/attachment';
import {CoReportApi} from '../../model/co-report/co-report-api';
import {observer} from 'mobx-react-lite';
import LottieComp from '../../components/lottie-comp';
import {UploadApi} from "../../model/upload/upload-api";
import ViewportControl from "../../components/viewport-control";



interface PdfDownloadProps {
    className: string;
    title: string;
    document?: JSX.Element;
    chart?: JSX.Element;
    fileName: string;
    documentName?: string;
    onClick?: VoidFunction;
    customerId?: string; //리포트를 서버에 저장할 경우 고객 아이디 설정
    onSave?: (attachment: Attachment[]) => void;

    onDownload?: VoidFunction;
}

function download (filename: string, url: string){
    const element = document.createElement('a');
    element.setAttribute('href', `${url}`);
    element.setAttribute('download', filename);
    element.setAttribute('target', '_blank');

    element.style.display = 'none';
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
};

const PdfDownload = observer(({
                                  className,
                                  title,
                                  document,
                                  chart,
                                  fileName,
                                  documentName,
                                  onClick,
                                  customerId,
                                  onSave,
                                  onDownload
                              }: PdfDownloadProps) => {
    const {loaded} = useFont('NotoSansKR');
    usePdfMode(false);
    const [makePdf, setMakePdf] = useState(0);
    const chartManager = useInjection(ChartManager);

    const saved = useRef(false);
    const coReportApi = useInjection(CoReportApi);
    const uploadApi = useInjection(UploadApi);
    const isMounted = useMountedState();

    if (makePdf === 1) {
        setTimeout(() => setMakePdf(2), 30); //버튼을 누른 후 화면 갱신이 바로 안 되는 경우가 있어서 화면 갱신 후 진행
    }
    const chartLoaded = chartManager.isLoaded;

    const loadingBtn = 
            <div className='full-background'>
                <div className='pop-inner dialog-type-a'>
                    <div className='text'>
                        <LottieComp jsonFile='pdf_loading' width='100%' speed={0.25} loop={false}/>
                        <p className='sub-title' style={{padding: '1.0rem 0'}}>
                            씨오리포트 생성중입니다.<br/>
                            사용자의 인터넷 환경에 따라 시간이<br/>
                            오래 걸릴 수 있습니다.
                        </p>
                    </div>
                    <div className='btn-area one-button'>
                        <button onClick={() => setMakePdf(0)} className='close-btn'>닫기</button>
                    </div>
                </div>
            </div>;

    return useMemo(() => {
        const PdfDownloadBtn =
            ({children, ...props}: { children: ReactNode } & ButtonHTMLAttributes<HTMLButtonElement>) => {
                return <button className={className} type='button' {...props}><i></i>{children}</button>;
            };

        if (makePdf === 0) {
            chartManager.clear();
            return <PdfDownloadBtn onClick={() => {
                onClick?.();
                setMakePdf(1);
            }}>{title}</PdfDownloadBtn>;
        } else if (makePdf === 2 && document && loaded && chartLoaded) {
            const uploadPdf = async  (customerId: string, blob: Blob) => {
                const fileInfo = await uploadApi.uploadSingleFile({'type': 'PR_REPORT', 'autoSave':false, 'fileName': fileName, 'documentName': documentName}, blob);

                asyncCall(coReportApi.saveCoReport, [customerId, fileInfo.fileID], (result) => {
                    onSave?.(result);
                }, isMounted, () => {
                    saved.current = false;
                });
            }


            return <>
                <ChartContainer document={chart}/>
                <PDFDownloadLink document={document}
                                 fileName={fileName}>
                    {({loading, blob, error}) => {
                        if (!loading && blob && !saved.current) {
                            saved.current = true;

                            if (customerId) {
                                uploadPdf(customerId, blob).then(() => {
                                    download(fileName, URL.createObjectURL(blob));
                                    if (onDownload) onDownload();
                                });
                            }
                            else {
                                download(fileName, URL.createObjectURL(blob));
                                if (onDownload) onDownload();
                            }
                        }

                        return !blob ? (customerId ? loadingBtn : <PdfDownloadBtn>생성중...</PdfDownloadBtn>) : <PdfDownloadBtn>{title}</PdfDownloadBtn>
                    }}
                </PDFDownloadLink>
                </>;
        } else {
            return <>
                <ChartContainer document={chart}/>
                {customerId ? loadingBtn : <PdfDownloadBtn>생성중...</PdfDownloadBtn>}
            </>;
        }
    }, [makePdf, document, loaded, chart, className, chartManager, title, onClick, chartLoaded, fileName, coReportApi.saveCoReport, isMounted, onSave, customerId]);
});

export default PdfDownload