import React from 'react';
import * as Yup from 'yup';
import classNames from 'classnames/bind';
import styles from './edit-info.module.scss';
import {Form, Formik} from 'formik';
import ReactTextareaAutosize from 'react-textarea-autosize';
import {ClosableProps} from '../../components/dialog';
import {useMountedState} from 'react-use';
import {useInjection} from 'inversify-react';
import {CustomerApi} from '../../model/customer/customer-api';
import {CustomerDetail} from '../../model/customer/dto/customer-detail';
import {InputBox} from './edit-common';
import {emailRule} from "../../util/input-util";

const cx = classNames.bind(styles);

interface EditKeyManInfoProps extends ClosableProps {
    customerId: string;
    setData: React.Dispatch<React.SetStateAction<CustomerDetail>>
}

const EditMnaPartnerInfo = ({customerId, setData, onClose}: EditKeyManInfoProps) => {
    const isMounted = useMountedState();
    const customerApi = useInjection(CustomerApi);

    return (
        <div className={cx('edit-info')}>
            <Formik
                initialValues={{name: '', group: '', phone: '', email: '', remark: ''}}
                validationSchema={Yup.object({
                    phone: Yup.string().min(11, '휴대폰 번호를 확인해 주세요.').matches(/^[0-9]+$/, '숫자만 입력해 주세요.'),
                    email: Yup.string().matches(emailRule, '이메일 형식이 아닙니다.')
                })}
                onSubmit={() => {

                }}>
                {({getFieldProps}) =>
                    <Form>
                        <div className={cx('button-area')}>
                            <button className={cx('back')} type='button' onClick={onClose}><i></i>돌아가기</button>
                            <button className={cx('save')} type='submit'><i></i>저장하기</button>
                        </div>
                        <div className={cx('input-area')}>
                            <div className={cx('scroll')}>
                                <p className={cx('title')}>소개파트너 정보</p>
                                <div className={cx('form-box')}>
                                    <InputBox title='이름' name='name' placeholder='이름 입력' inputWidth='52rem' inputboxCn={cx('input-box')}/>
                                    <InputBox title='소속' name='group' placeholder='소속 입력' inputWidth='52rem' inputboxCn={cx('input-box')}/>
                                    <InputBox title='전화번호' name='phone' maxLength={11} placeholder='숫자만 입력' inputWidth='52rem' inputboxCn={cx('input-box')}/>
                                    <InputBox title='이메일' name='email' placeholder='이메일 입력' inputWidth='52rem' inputboxCn={cx('input-box')}/>
                                    <div className={cx('input-box')}>
                                        <label>특이사항</label>
                                        <ReactTextareaAutosize maxLength={300} placeholder='내용을 입력해 주세요.'
                                                               {...getFieldProps('remark')}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Form>
                }
            </Formik>
        </div>
    );
};

export default EditMnaPartnerInfo;