import React, {useRef, useState} from 'react';
import Dialog, {ClosableProps} from '../../components/dialog';
import {useMountedState} from 'react-use';
import {useInjection} from 'inversify-react';
import {AuthStore} from '../../model/auth/auth-store';
import {asyncCall} from '../../util/util';
import * as Yup from 'yup';
import FormikField from '../../components/formik-field';
import {Form, Formik} from 'formik';
import {strEnterAuthNum, strNumberOnly, strWrongAuthNum} from '../../data/strings';

interface InputAuthNumProps extends ClosableProps {
    token: string;
    seq: string;
}

const InputAuthNum = ({token, seq, onClose}: InputAuthNumProps) => {
    const [btnActive, setBtnActive] = useState(false);
    const isMounted = useMountedState();
    const authStore = useInjection(AuthStore);
    const formRef = useRef<any>(null);

    return (
        <Dialog mainBtnText='인증하기' onClose={onClose} active={btnActive}
                onAccept={() => formRef.current.submitForm()}>
            <div className='title light'>{'회원가입시 등록했던 휴대폰 번호로\n인증번호가 전송되었습니다.'}</div>
            <Formik
                initialValues={{authNum: ''}}
                validationSchema={Yup.object({
                    authNum: Yup.string().required(strEnterAuthNum).min(6, strEnterAuthNum)
                })}
                onSubmit={(values, {setFieldError}) => {
                    asyncCall(authStore.loginCheckCert, [seq, values.authNum, token, authStore.getDeviceId()], (rs) => {
                        authStore.setToken(rs.at, rs.member);
                        onClose();
                    }, isMounted, () => {
                        setFieldError('authNum', strWrongAuthNum);
                    });
                }}
                innerRef={formRef}>
                {({handleChange, setFieldError}) =>
                    <Form>
                        <div className='input-box'>
                            <FormikField
                                name='authNum' placeholder='인증번호 6자리 입력' type='text' maxLength={6}
                                autoFocus
                                onChange={(e: any) => {
                                    const value = e.target.value;
                                    if (value.search(/\D/) >= 0) {
                                        setFieldError('authNum', strNumberOnly);
                                    } else {
                                        handleChange(e);
                                        setBtnActive(value.length === 6);
                                    }
                                }}/>
                        </div>
                    </Form>}
            </Formik>
        </Dialog>
    );
};

export default InputAuthNum;
