import {Canvas, Font, Image, Text, View} from '@react-pdf/renderer';
import {PdfContext} from '../../../App';
import React, {useContext, useEffect, useState, JSX} from 'react';
import {FontDescriptor} from '@react-pdf/types';
import {ChartImage} from '../../components/chart-image';
import {ChartJs} from '../../components/chart-js';
import {ChartImageProducer} from '../../components/chart-image-producer';


export const mergeStyle = (style?: any) => {
    if (style instanceof Array) {
        const newStyle: { [key: string]: string } = {}
        style.forEach((it) => {
            for (const key in it) {
                newStyle[key] = it[key]
            }
        });
        return newStyle;
    }
    return style;
}

export const NullTag = ({children, style}: { children?: any, style?: any }) => {
    return <div style={mergeStyle([{boxSizing: 'border-box'}, mergeStyle(style)])}>{children}</div>
}

export const ImgTag = ({src, style, alt}: { children?: any, src?: any, style?: any, alt?: string | undefined }) => {
    return <img src={src} style={mergeStyle(style)} alt={alt}/> //이미지 아래에 여백 생기는 문제 수정
}

const defaultStyle = {//react-pdf 기본값을 pdf와 html에 함께 설정
    letterSpacing: '-0.7px', //react-pdf는 전역으로 동작, html에서는 div에 설정된 경우 기본값을 변경하려면 텍스트 마다 따로 설정해줘야 함
    whiteSpace: 'pre-wrap', //react-pdf 기본값
    lineHeight: 1.5, //react-pdf 기본값
    wordBreak: 'break-all', //react-pdf 기본값
}

const BodyTag = ({children, style, id}: { children?: any, style?: any, id?: string }) => {
    return <div id={id} style={mergeStyle([defaultStyle, style])}>{children}</div>
}

const DummyTag = ({children}: { children?: any, style?: any }) => {
    return <>{children}</>
}

const DummyTag0 = () => {
    return <></>
}

const CanvasTag = () => {
    return <canvas/>;
}

export const usePdfMode = (isPdf: boolean, isChart: boolean = false) => {
    const value = useContext(PdfContext);
    value.isPdf = isPdf;
    value.isChart = isChart;
}

export const usePdfComponents = () => {
    const {isPdf, isChart} = useContext(PdfContext);
    return isPdf ?
        (isChart ? {
                isPdf: isPdf,
                Text: DummyTag0,
                View: DummyTag,
                Image: DummyTag,
                Chart: ChartImageProducer,
                Canvas: Canvas,
                Body: DummyTag
            }
            : {isPdf: isPdf, Text: Text, View: View, Image: Image, Chart: ChartImage, Body: BodyTag, Canvas: Canvas})
        : {isPdf: isPdf, Text: NullTag, View: NullTag, Image: ImgTag, Chart: ChartJs, Body: BodyTag, Canvas: Canvas};
}

const getFontList = (fontFamily: string): FontDescriptor[] => {
    const list = Font.getRegisteredFonts();
    let src: any[] = [];
    for (const f in list) {
        if (f === fontFamily) src = src.concat(list[f].sources);
    }
    return src.map(f => ({fontFamily: fontFamily, fontStyle: f.fontStyle, fontWeight: f.fontWeight}));
}

const isLoaded = (list: FontDescriptor[]) => {
    for (const font of list) {
        if (Font.getFont(font)?.loading === true) return false;
    }
    return true;
}

export const useFont = (fontFamily: string) => { //한글깨짐 방지. react-pdf에 폰트가 로딩되었는지 확인하는 부분
    const [state, setState] = useState({loaded: false, error: false});
    useEffect(() => {
        (async () => {
            const list = getFontList(fontFamily);
            for (const font of list) {
                await Font.load(font);
            }
            const timer = setInterval(() => {
                if (isLoaded(list)) {
                    clearInterval(timer);
                    clearTimeout(timer2);
                    setState({loaded: true, error: false});
                }
            }, 100);
            const timer2 = setTimeout(() => {
                clearInterval(timer);
                setState({loaded: false, error: true});
            }, 60000);
        })();
    }, [fontFamily]);
    return state;
}


const tagReg = new RegExp(/<[bu]>\s*(.+?)\s*<\/[bu]>/g);
export const tagStrToPdfText = (text: string, onMakeText: (text: string, origin?: string) => JSX.Element) => {
    let result: React.ReactNode[] = [];
    let match = text.match(tagReg);

    if (match) {
        let textArr: string[] = [];

        for (let i = 0; i < match.length; i++) {
            if (i === 0) {
                textArr = text.split(match[i]);
            } else {
                textArr = textArr[1].split(match[i]);
            }

            result.push(textArr[0]);
            result.push(onMakeText(match[i].split(tagReg)[1], match[i]));

            if (i === match.length - 1) {
                result.push(textArr[textArr.length - 1]);
            }
        }
    } else {
        result.push(text);
    }

    return result;
}

const tagReg2 = new RegExp(/<[iu]>\s*(.+?)\s*<\/[iu]>/g);
export const tagStrToPdfText2 = (text: string, onMakeText: (text: string, origin?: string) => JSX.Element) => {
    let result: React.ReactNode[] = [];
    let match = text.match(tagReg2);

    if (match) {
        let textArr: string[] = [];

        for (let i = 0; i < match.length; i++) {
            if (i === 0) {
                textArr = text.split(match[i]);
            } else {
                textArr = textArr[1].split(match[i]);
            }

            result.push(textArr[0]);
            result.push(onMakeText(match[i].split(tagReg2)[1], match[i]));

            if (i === match.length - 1) {
                result.push(textArr[textArr.length - 1]);
            }
        }
    } else {
        result.push(text);
    }

    return result;
}

const tagReg3 = new RegExp(/<[pu]>\s*(.+?)\s*<\/[pu]>/g);
export const tagStrToPdfText3 = (text: string, onMakeText: (text: string, origin?: string) => JSX.Element) => {
    let result: React.ReactNode[] = [];
    let match = text.match(tagReg3);

    if (match) {
        let textArr: string[] = [];

        for (let i = 0; i < match.length; i++) {
            if (i === 0) {
                textArr = text.split(match[i]);
            } else {
                textArr = textArr[1].split(match[i]);
            }

            result.push(textArr[0]);
            result.push(onMakeText(match[i].split(tagReg3)[1], match[i]));

            if (i === match.length - 1) {
                result.push(textArr[textArr.length - 1]);
            }
        }
    } else {
        result.push(text);
    }

    return result;
}