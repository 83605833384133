import styles from './edit-financial-info.module.scss';
import classNames from 'classnames/bind';
import {Form, Formik, FormikState} from 'formik';
import React, {useEffect, useRef, useState} from 'react';
import {PrFrStat} from '../../model/customer/dto/financial-statement';
import {ymdToDateString} from '../../util/str-util';
import {useMountedState} from 'react-use';
import {useInjection} from 'inversify-react';
import {CustomerApi} from '../../model/customer/customer-api';
import {asyncCall} from '../../util/util';
import {toast} from 'react-toastify';
import {strSaved} from '../../data/strings';
import {CustomerDetail} from '../../model/customer/dto/customer-detail';
import {NumberField} from './edit-common';
import {FsCodeInputDto} from "../../model/customer/dto/fs-code-dto";
import LottieComp from '../../components/lottie-comp';
import {SelectItem} from "../../model/types";
import {DialogManager} from "../../components/dialog-manager";
import AddFinancialData from "./add-financial-data";
import CustomTooltip from "../../components/custom-tooltip";
import BasicSelectBox from "../../components/basic-select-box";

const cx = classNames.bind(styles);

interface EditFinancialInfoProps {
    customerId: string;
    initYear?: string;
    onClose: (lastYear: number) => void;
    setData: React.Dispatch<React.SetStateAction<CustomerDetail>>;
    enpTyp: string;
}

const EditFinancialInfoV2 = ({customerId, setData, onClose, initYear, enpTyp}: EditFinancialInfoProps) => {
    const customerApi = useInjection(CustomerApi);

    const [loading, setLoading] = useState(true);
    const inputFsCodeRef = useRef<{ [key: string]: FsCodeInputDto[] }>(undefined);
    const prFrStatMapRef = useRef<{ [key: string]: PrFrStat }>(null);
    const frStatListRef = useRef<PrFrStat[]>([]);
    const [selectYear, setSelectYear] = useState<string>("");

    useEffect(() => {
        async function loadEditFinancialStmtV2() {
            if (customerId) {
                const fsCodeResult = await customerApi.getInputFsCode();
                inputFsCodeRef.current = fsCodeResult.val;

                const result = await customerApi.getEditFinancialStmtV2(customerId);

                if (result.success) {
                    const statMap: { [key: string]: PrFrStat } = {};

                    for (const frStat of result.val!) {
                        statMap[frStat.refDt.toString()] = frStat;
                    }
                    prFrStatMapRef.current = statMap;
                    frStatListRef.current = result.val!;

                    setSelectYear(result.val![0].refDt.toString());
                    setLoading(false);
                }
            }
        }

        loadEditFinancialStmtV2();
    }, [customerId]);

    if (loading) {
        return <div className='full-background'>
            <div className='loading-box'><LottieComp width='10.0rem' height='10.0rem' jsonFile='loading'/></div>
        </div>
        // return <p style={{position:'absolute'}}></p>;
    }

    let prFrStat = prFrStatMapRef.current![selectYear!];
    const yearList: SelectItem[] = frStatListRef.current!.map((it, i) => ({
        id: it.refDt.toString(),
        title: it.refDt.toString()
    })) ?? [];

    return <EditFinancialInfoInner
        key={selectYear}
        onClose={onClose}
        setData={setData}
        customerId={customerId}
        yearList={yearList}
        frStat={prFrStat}
        selectYear={selectYear}
        setSelectYear={setSelectYear}
        fsCodeTbl={inputFsCodeRef.current!}
        enpTyp={enpTyp}
    />;
}

interface EditFinancialInfoInnerProps {
    customerId: string;
    fsCodeTbl: { [key: string]: FsCodeInputDto[] };
    yearList: SelectItem[];
    onClose: (lastYear: number) => void;
    setData: React.Dispatch<React.SetStateAction<CustomerDetail>>;
    frStat: PrFrStat
    selectYear: string;
    setSelectYear: React.Dispatch<React.SetStateAction<string>>;
    enpTyp: string;
}

const EditFinancialInfoInner = ({
                                    customerId,
                                    setData,
                                    frStat,
                                    onClose,
                                    enpTyp,
                                    yearList,
                                    selectYear,
                                    setSelectYear,
                                    fsCodeTbl
                                }: EditFinancialInfoInnerProps) => {
    const isMounted = useMountedState();
    const customerApi = useInjection(CustomerApi);
    const dialogManager = useInjection(DialogManager);

    const [tab, setTab] = useState(true);

    const bsTbl = fsCodeTbl['BS'];
    const isTbl = fsCodeTbl['IS'];

    if (!frStat) return null;

    const depNum = (tab ? '1' : '2') + '_';
    const field = (tab ? 'bss' : 'iss') + '.';
    const fs = tab ? frStat.bss : frStat.iss;
    const tbl = tab ? bsTbl : isTbl;

    const closeFinancialInfo = () => {
        onClose(parseInt(selectYear));
    }

    const deleteManualFs = () => {
        dialogManager.confirm("수동 입력 데이터를 삭제하시겠습니까?", "", async () => {
            const result = await customerApi.deleteManualFinancialStmt(customerId, frStat.refDt)

            if (result.success) {
                setData(data => ({...data, fss: result.val}));
                // closeFinancialInfo();
                onClose(-1);
                toast.success("수동 데이터가 삭제 되었습니다.");
            }
        }, "삭제");
    }

    const openLoadPdf = (resetForm: (nextState?: Partial<FormikState<PrFrStat>>) => void, applyYear?: number) => {
        dialogManager.open(AddFinancialData, {
            workYear: parseInt(selectYear), customerId, initApplyYear: applyYear, onComplete: (fsData: PrFrStat) => {
                // updt
                setData(data => ({...data, fss: data.fss?.map((fs) => (fs.refDt === fsData.refDt) ? fsData : fs)}));
                resetForm({values: fsData});
            }
        });
    }

    return <Formik
        initialValues={frStat}
        onSubmit={(values) => {
            const bsReqInputs = bsTbl.filter((bs) => enpTyp === "1" ? bs.reqInput : bs.reqInputSole)
                .filter((bs) => (!values.bss[0].data[bs.coNod]))
                .map((bs) => bs.name);

            if (bsReqInputs.length > 0) {
                dialogManager.alert(`[재무상태표] 필수 데이터가 입력되지 않았습니다. [${bsReqInputs.join(',')}]`);
                return;
            }

            const isReqInputs = isTbl.filter((is) => enpTyp === "1" ? is.reqInput : is.reqInputSole)
                .filter((is) => (!values.iss[0].data[is.coNod]))
                .map((is) => is.name);

            if (isReqInputs.length > 0) {
                dialogManager.alert(`[손익계산서] 필수 데이터가 입력되지 않았습니다. [${isReqInputs.join(',')}]`);
                return;
            }

            asyncCall(customerApi.saveFinancialStmtV2, [customerId, values], result => {
                setData(data => ({...data, fss: result}));
                closeFinancialInfo();
                toast.success(strSaved);
            }, isMounted, (errorMessage) => {
                toast.error(errorMessage);
            });
        }}>
        {({values, setFieldValue, resetForm}) =>
            <Form className={cx('edit-financial-info')}>
                <div className={cx('btn-area')}>
                    <button className={cx('back')} type='button' onClick={closeFinancialInfo}><i></i>돌아가기
                    </button>
                    <button className={cx('save-btn')} type='submit'><i></i>저장하기</button>
                </div>
                <div className={cx('scroll-box')}>
                    <div className={cx('scroll-area')}>
                        <p className={cx('big-title')}>
                            재무 정보
                            {(frStat!.manual && !frStat!.temp) &&
                                <button className={cx('reset-btn')} type='button' onClick={deleteManualFs}><i></i>초기화
                                </button>}
                        </p>
                        <div className={cx('select-line')}>
                            <div className={cx('tab-print-line')}>
                                <div className={cx('tab-area')}>
                                    <button type='button' className={cx(tab && 'on')} onClick={() => setTab(true)}>재무상태표
                                    </button>
                                    <button type='button' className={cx(!tab && 'on')}
                                            onClick={() => setTab(false)}>손익계산서
                                    </button>
                                </div>
                                <button></button>
                            </div>
                            <div className={cx('button-area')}>
                                {/*<button
                                    className={cx('skyblue-btn')}
                                    type='button'
                                    onClick={() => openLoadPdf(resetForm)
                                    }>
                                    <i></i>표준재무제표 업로드
                                </button>*/}

                                <p className={cx('title')}>결산연도</p>


                                <BasicSelectBox
                                    selectList={yearList}
                                    selected={selectYear}
                                    inputWidth='4.6rem' unit='년'
                                    onChange={id => {
                                        setSelectYear(id as string);

                                        // setFrStat(frStatList.filter((fr) => fr.refDt.toString() == id as string)[0]);

                                    }}
                                />

                            </div>
                        </div>
                        <div className={cx('finance-edit-table')}>
                            <p className={cx('title-area')}>
                                {tab ?
                                    <span
                                        className={cx('title')}>※ 자동조회 데이터가 없을 경우 직접 입력하거나, 표준재무제표 PDF파일을 업로드해 주세요.</span>
                                    : <span className={cx('title')}>※ 손익계산서의 값을 직접 입력해 주세요.</span>
                                }
                                <span className={cx('unit')}>(단위: 천원)</span>
                            </p>
                            <div className={cx('table-wrap')}>
                                <div className={cx('head-box')}>
                                    <p className={cx('head')}>계정명</p>
                                    {fs.slice(0).reverse().map((it, i) =>
                                        <div className={cx('head')} key={i}>
                                            <span>{ymdToDateString(it.stDate)}</span>
                                            <button
                                                type='button'
                                                onClick={() => openLoadPdf(resetForm, parseInt(it.stDate.toString().slice(0, 4)))}
                                                className={cx('upload-btn')}><CustomTooltip title='표준재무제표 PDF 업로드'
                                                                                            position={i === 2 ? 'right' : 'center'}/>
                                            </button>
                                        </div>
                                    )}
                                </div>
                                {tbl.map((it, i) =>
                                    <ul key={i}
                                        className={cx('body-box', 'dep' + depNum + (it.coDepth ? it.coDepth : it.kdDepth))}>
                                        <li className={cx('body')}>{it.name}</li>
                                        {[...Array(3)].map((_, j) =>
                                            <li key={j} className={cx('body')}>
                                                <NumberField
                                                    name={field + (2 - j) + '.data.' + (it.coNod)}
                                                    onBlur={() => {
                                                        // 현재는 하드코딩.. 이후 니즈가 더 생기면 기능으로 뺴자...
                                                        // 자본 = 자산 - 부채
                                                        if (it.coNod === "asset" || it.coNod === "liability") {
                                                            const asset = parseInt(values.bss[2 - j].data["asset"] ? values.bss[2 - j].data["asset"] : "0")
                                                            const liability = parseInt(values.bss[2 - j].data["liability"] ? values.bss[2 - j].data["liability"] : "0")

                                                            setFieldValue(field + (2 - j) + '.data.equity', asset - liability)
                                                        }
                                                    }
                                                    }
                                                    negative={true}/></li>)}

                                    </ul>)}
                            </div>
                        </div>
                    </div>
                </div>
            </Form>
        }
    </Formik>
}

export default EditFinancialInfoV2;