import React, {forwardRef, ReactNode, JSX} from 'react';
import {Field, FieldAttributes, FieldProps} from 'formik';
import SelectBox from '../../components/select-box';
import {formatNum, numberOnly, toDateString, YMD, ymdFormat, ymdToDate} from '../../util/str-util';
import FormikErrorMsg from '../../components/formik-error-msg';
import AutosizeInput from 'react-input-autosize';
import {ko} from 'date-fns/locale';
import ReactDatePicker, {ReactDatePickerProps} from 'react-datepicker';
import {SelectItem} from '../../model/types';
import TooltipPop from '../../components/tooltip-pop';

export const CustomInput = ({field, form, ...props}: { field: any, form: any }) => {
    if (field.value == null) field.value = ''; //value가 null이면 배열에 insert할 때 업데이트가 제대로 안 됨
    return <input {...field} {...props} />;
}

/*
export const NumberInput = ({value, onChange, ...props}
                                : { value: any, onChange: (e: React.ChangeEvent<HTMLInputElement>) => void }) => {
    if (value == null) value = '';
    else value = formatNum(value, '', '');
    return <input value={value} onChange={onChange} {...props}/>;
}
*/

export interface InputBoxProps {
    name?: string;
    title?: ReactNode;
    unit?: string;
    value?: string;
    placeholder?: string;
    disabled?: boolean;
    readOnly?: boolean;
    inputboxCn?: string;
    inputWidth?: string;
    minLength?: number;
    maxLength?: number;
    type?: string;
    children?: JSX.Element;
    requiredField?: boolean;
    tooltip?: string | {title:string, desc:string}[];
    onClick?: () => void;
    // onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
    // onChange?: (e: any) => void;
}

export const InputBox = ({
                             name,
                             title,
                             unit,
                             value,
                             placeholder = '입력',
                             disabled,
                             readOnly,
                             inputboxCn,
                             inputWidth,
                             minLength,
                             maxLength,
                             type,
                             children,
                             requiredField,
                             tooltip,
                             onClick = () => null,
                            //  onChange = () => null,
                            //  onChange
                         }: InputBoxProps) => {
    return (
        <div className={`input-box ${inputboxCn}`}>
            {title &&
                <label>{title}{requiredField && <i className='required-field'> *</i>}{tooltip && <TooltipPop contents={tooltip} eventStop={true} />}</label>
            }
            <div className={`for-unit ${unit ? 'hasUnit':''}`} style={{width: inputWidth ? inputWidth : '13.3rem'}}>
                {children ? children :
                    (name ?
                            <Field className='input-txt' name={name} component={CustomInput}
                                   placeholder={placeholder} type={type} disabled={disabled} readOnly={readOnly}
                                   maxLength={maxLength} min={minLength} onClick={onClick} /> :
                            <input className='input-txt' type='text' value={value ?? ''} readOnly/>
                    )}

                {unit != null &&
                    <span className='unit'>{unit}</span>}
                {name && <FormikErrorMsg name={name}/>}
            </div>
        </div>
    );
}

export interface SelectInputBoxProps extends InputBoxProps {
    selectList?: string[] | SelectItem[];
    openAbove?: boolean;
    intIdx?: boolean;
    selectBoxCn?: string[];
    onChange?: (id: any) => void;
}

export const SelectInputBox = ({
                                   name,
                                   value,
                                   selectList,
                                   openAbove = false,
                                   intIdx,
                                   onChange,
                                   ...props
                               }: SelectInputBoxProps) => {
    const strList = selectList && selectList.length > 0 && typeof selectList[0] === "string";
    const list = (strList ? selectList!.map((it, i) => {
        return {id: intIdx ? i : it, title: it}
    }) : selectList) as SelectItem[];
    const externalValue = strList && !intIdx; //문자 배열이고 정수 아이디를 사용하지 않는 경우 title값을 저장하지 않고 formik의 값을 사용

    // console.log(strList);
    // console.log(intIdx);
    // console.log(externalValue);
    // console.log(value);

    return (
        <InputBox name={name} {...props}>
            {list && !props.disabled ? <Field name={name}>
                    {({field: {value}, form}: FieldProps) => {
                        return <SelectBox selectList={list} selectedTitle={externalValue ? value : null}
                                          selected={value} externalValue={externalValue}
                                          placeholder={props.placeholder ?? '선택'}
                                          selectBoxCn={['gray-box', `${props.selectBoxCn ? props.selectBoxCn : ''}`]} inputBoxCn='font14'
                                          width='100%' openAbove={openAbove}
                                          onChange={id => {
                                              if (onChange) onChange(id);
                                              else name && form.setFieldValue(name, id)
                                          }}/>
                    }}
                </Field> :
                <input className='input-txt' type='text' value={value ?? ''} readOnly/>
            }
        </InputBox>
    );
}

export const SelectInputBox2 = ({
                                   name,
                                   value,
                                   selectList,
                                   openAbove = false,
                                   intIdx,
                                   onChange,
                                   ...props
                               }: SelectInputBoxProps) => {
    const strList = selectList && selectList.length > 0 && typeof selectList[0] === 'string';

    const list = (strList ? selectList!.map((it, i) => {
        return {id: intIdx ? i : it, title: it}
    }) : selectList) as SelectItem[];

    return (
        <InputBox name={name} {...props}>
            {list && !props.disabled ? <Field name={name}>
                    {({field: {value}, form}: FieldProps) => {
                        return <SelectBox selectList={list} selectedTitle={value}
                                          selected={value} externalValue={true}
                                          placeholder={props.placeholder ?? '선택'}
                                          selectBoxCn={['gray-box', `${props.selectBoxCn ? props.selectBoxCn : ''}`]} inputBoxCn='font14'
                                          width='100%' openAbove={openAbove}
                                          onChange={id => {
                                              if (onChange) onChange(id);
                                              else name && form.setFieldValue(name, id)
                                          }}/>
                    }}
                </Field> :
                <input className='input-txt' type='text' value={value ?? ''} readOnly/>
            }
        </InputBox>
    );
}

interface NumberFieldProps extends FieldAttributes<any> {
    autoSize?: boolean;
    negative?: boolean;
    placeholder?: string;
    unit?: string;
}

export const NumberField = ({name, value, autoSize, negative, multiple, unit, ...props}: NumberFieldProps) =>
    <Field name={name}>
        {({field, form}: FieldProps) => {
            let numFieldVal = name && value === undefined ? field.value : value;
            if (numFieldVal != null && multiple) numFieldVal /= multiple;

            const props2 = {
                name: name,
                type: 'text',
                value: formatNum({num: numFieldVal, unit: '', empty: '', type: "input"}),
                onChange: (e: any) => {
                    let val: any = numberOnly(e.target.value, negative);
                    if (val && multiple) val = Number(val) * multiple;
                    form.setFieldValue(field.name, val, true);
                },
                onBlur: name ? (e: any) => {
                    form.handleBlur(e)
                } : undefined
            };
            return autoSize ? <AutosizeInput className='input-txt' maxLength={30} {...props2} {...props}/> :
                <input className='input-txt' {...props2} {...props}/>
        }}
    </Field>;

interface NumberInputBoxProps {
    name?: string;
    title?: ReactNode;
    unit?: string;
    value?: string | number;
    placeholder?: string;
    autoSize?: boolean;
    readOnly?: boolean;
    inputboxCn?: string;
    inputWidth?: string;
    negative?: boolean;
    multiple?: number;
    maxLength?: number;
    tooltip?: string | {title:string, desc:string}[]
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export const NumberInputBox = ({
                                   name,
                                   title,
                                   unit,
                                   value,
                                   autoSize,
                                   inputboxCn,
                                   inputWidth,
                                   negative,
                                   multiple,
                                   maxLength,
                                   tooltip,
                                   ...props
                               }: NumberInputBoxProps) => {

    return <InputBox name={name} title={title} unit={unit} inputboxCn={autoSize ? 'flexable-box' : inputboxCn}
                     inputWidth={inputWidth} tooltip={tooltip}>
        <NumberField name={name} value={value} autoSize={autoSize} negative={negative} multiple={multiple}
                     unit={unit} {...props}
                     placeholder={props.placeholder ?? '입력'} maxLength={maxLength}/>
    </InputBox>
}

//상담일지에서 사용중
export const DatePicker = ({
                               name,
                               allowNull,
                               cn,
                               icon,
                               ...props
                           }: { allowNull?: boolean, cn?:string, icon?: string } & Omit<ReactDatePickerProps, 'onChange'>) => {

    const CustomInput = forwardRef<HTMLButtonElement>(({ value, onClick }: any, ref) => (
        <button className={`input-txt ${cn}`} onClick={onClick} type='button' ref={ref}>
            {value}{icon && <i></i>}
        </button>
    ));

    return <>
        <Field name={name}>
            {({field, form}: FieldProps) =>
                <ReactDatePicker
                    name={name}
                    locale={ko}
                    dateFormat={ymdFormat}
                    dateFormatCalendar='yyyy년 M월'
                    selected={ymdToDate(field.value)}
                    customInput={<CustomInput />}
                    showMonthDropdown
                    showYearDropdown
                    peekNextMonth
                    dropdownMode="select"
                    onChange={date => {
                        if (date) {
                            form.setFieldValue(field.name, toDateString(date, YMD), true);
                        } else if (allowNull) {
                            form.setFieldValue(field.name, '', true);
                        }
                    }}
                    onBlur={(e: any) => {
                        form.handleBlur(e)
                    }}
                    {...props}
                />
            }
        </Field>
        {name && <FormikErrorMsg name={name}/>}
    </>;
}

export const DateInputBox = ({name, ...props}: InputBoxProps) =>
    <InputBox name={name} {...props} />
        {/* <Field name={name}>
            {({field, form}: FieldProps) => {

                return <input 
                    name={name}
                    type='text'
                    placeholder={props.placeholder}
                    readOnly={props.readOnly}
                    disabled={props.disabled}
                    onChange={(e:any) => {
                        let date = ymdToDateString(e.target.value);
                        console.log(date);
                        form.setFieldValue(field.name, date, true);
                    }}
                    onBlur={(e: any) => {
                        form.handleBlur(e)
                    }}
                    className={cx('input-txt')}/>
            }}
        </Field>
    </InputBox> */}

export const familyList: string[] = ['배우자', '성년자녀', '미성년자녀', '성년손주', '미성년손주', '부모', '기타친족'];

export const mdmRelList: string[] = ['본인', '배우자', '성년자녀', '미성년자녀', '성년손주', '미성년손주', '부모', '조부모', '형제자매', '사위', '며느리', '기타친족', '임원', '직원', '관계회사', '관계회사임원', '주주회사', '타인', '기타'];//배우자, 임원, 직원 추가함

