import styles from './mna-info.module.scss';
import classnames from 'classnames/bind';
import React from "react";
import {Field, Form, Formik} from "formik";
import TooltipPop from "../../components/tooltip-pop";
import CompanyTypeForm from "./component/company-type-form";
import {CustomerDetail} from "../../model/customer/dto/customer-detail";
import ConnectChannelTalk from "./component/connect-channel-talk";
import { useInjection } from 'inversify-react';
import { CustomerInputApi } from '../../model/customer/customer-input-api';
import { EarningsEstimate } from '../../model/customer/dto/customer-mna-brokerage-dto';
import { DialogManager } from '../../components/dialog-manager';
import MnaIntroductionFeePolicyPop from "./component/mna-introduction-fee-policy-pop";
import { CustomerApi } from '../../model/customer/customer-api';
import AcquirerInfoForm from "./component/acquirer-info-form";
import { CustomerMnaNeedsDto } from '../../model/customer/dto/customer-mna-needs-dto';
import { CustomerMnaNeedsForm } from '../../model/customer/form/customer-mna-needs-form';

const cx = classnames.bind(styles);

interface MnaInfoProp {
    data: CustomerDetail
    needs: CustomerMnaNeedsDto
    setData: React.Dispatch<React.SetStateAction<CustomerDetail>>
    setNeeds: React.Dispatch<React.SetStateAction<CustomerMnaNeedsDto | undefined>>
}

const MnAInfo = ({data, needs, setData, setNeeds} : MnaInfoProp) => {
    const customerInputApi = useInjection(CustomerInputApi);
    const customerApi = useInjection(CustomerApi);
    const dialogManager = useInjection(DialogManager);

    if (!needs) return <div></div>;

    const brokerage = needs.brokerage;
    const acq = needs.acq;

    let parentCode = "";
    let businessName = "";

    if (brokerage?.bizAreaInfo && brokerage.bizAreaInfo !== null && brokerage.bizAreaInfo.length === 1 && brokerage.bizAreaInfo[0].parentCode === "기타") {
        businessName = brokerage.bizAreaInfo[0].parentCode;
    } else {
        brokerage?.bizAreaInfo?.map((info) => {
            if(parentCode === "") {
                parentCode = info.parentCode;
            } else {
                parentCode += "," + info.parentCode;
            }
    
            info.subCodes?.map((sub) => {
                if (businessName === "") {
                    businessName = sub;
                } else {
                    businessName += "," + sub;
                }
            });
        })
    }

    // const nowYear =new Date().getFullYear();
    
    const fss = data.fss && data.fss.length > 0 ? data.fss : undefined;
    const nowYear = fss && fss[0].refDt ? fss[0].refDt : new Date().getFullYear();

    const perform: EarningsEstimate[] = [
        {
            year: nowYear + 1,
            sales: 0,
            salesGrowthRate: 0,
            profitRate: 0
        },
        {
            year: nowYear + 2,
            sales: 0,
            salesGrowthRate: 0,
            profitRate: 0
        },
        {
            year: nowYear + 3,
            sales: 0,
            salesGrowthRate: 0,
            profitRate: 0
        },
        {
            year: nowYear + 4,
            sales: 0,
            salesGrowthRate: 0,
            profitRate: 0
        },
        {
            year: nowYear + 5,
            sales: 0,
            salesGrowthRate: 0,
            profitRate: 0
        },
    ]

    const saveExcel = async (type: string) => {
        const shareMemberNo = data.share && data.share.srcMember.memberNo;
        const loadResult = await customerInputApi.excelMnaNeeds(data.customer!.id, shareMemberNo ?? "", type);

        if (loadResult.success) {
            window.location.href = customerApi.makeDownloadFile(loadResult.data!);
        } else {
            if (loadResult.message == "NoData") {
                dialogManager.alert("M&A 니즈 정보를 저장 후 다시 시도해주십시오.");
            } else {
                dialogManager.alert("상담시트 인쇄에 실패했습니다.");   
            }
        }
    }

    return(
        <Formik
            initialValues={{
                companyType: needs && needs.type && needs.type !== null ? needs.type : "NONE",
                perform: brokerage && Object.keys(brokerage).length !== 0 && brokerage.earningsEstimates !== null && brokerage.earningsEstimates!.length > 0 ? brokerage.earningsEstimates : perform,
                businessName: businessName,
                brokerage: brokerage,
                acq: acq,
            }}
            onSubmit={async (values) => {
                values.brokerage = {
                    ...values.brokerage,
                    earningsEstimates: values.perform
                };

                const form: CustomerMnaNeedsForm = {
                    shareMemberNo: data.share && data.share !== null ? data.share.srcMember.memberNo : "",
                    enpNm: data.customer?.enpNm ?? "",
                    ceoName: data.customer?.rep[0].name ?? "",
                    type: values.companyType ?? "NONE",
                    brokerage: values.brokerage,
                    acq: values.acq,
                };

                const result = await customerInputApi.updateMnaNeeds(data.customer!.id, form);

                if (result.success) {
                    dialogManager.alert("저장되었습니다.");

                    const dto: CustomerMnaNeedsDto = {
                        id: data.customer!.id,
                        type: form.type,
                        brokerage: form.brokerage,
                        acq: form.acq,
                        createAt: needs.createAt
                    }

                    data.customer!.companyInfo.mainProduct = form.brokerage?.mainProduct;

                    setNeeds(dto);
                    setData(data => {
                        return {
                            ...data
                        }
                    })
                } else {
                    dialogManager.alert("저장에 실패했습니다.");
                }

            }}>
            {({values}) =>

                <div className={cx('mna-info')}>
                    <Form>
                        <div className={cx('title-line')}>
                            <p className={cx('title')}>
                                M&A 니즈
                                <button className={cx('status-btn')} type='button' onClick={() => {dialogManager.open(MnaIntroductionFeePolicyPop, {})}}>M&A 소개수수료 정책<i></i></button>
                            </p>
                            <div className={cx('btn-area')}>
                                {(values.companyType === 'CHECK' || values.companyType === 'SELL' || values.companyType === "ACQUIRE") &&
                                    <button className={cx('print-btn')} type='button' onClick={() => {saveExcel(values.companyType)}}><i></i>상담시트 인쇄</button>
                                }
                                <button className={cx('save-btn')} type='submit' onClick={() => {}}><i></i>저장하기</button>
                            </div>
                        </div>
                        <div className={cx('content')}>
                            <div className={cx('company-type')} role='group'>
                                <p className={cx('type-title')}>니즈 유형</p>
                                <label className={cx('radio-btn')}>
                                    <Field
                                        type='radio'
                                        name='companyType'
                                        value='NONE'/>
                                    해당없음
                                </label>
                                <label className={cx('radio-btn')}>
                                    <Field
                                        type='radio'
                                        name='companyType'
                                        value='CHECK'/>
                                    검토가능
                                    <TooltipPop contents="매각 검토가능기업으로 등록만 해두면, 잠재 인수자가 해당 기업과 유사한 조건에 관심을 보일 경우 씨오파트너 회원의 소개 연결을 통해 고객사 미팅을 진행할 수 있습니다." eventStop={false} />
                                </label>
                                <label className={cx('radio-btn')}>
                                    <Field
                                        type='radio'
                                        name='companyType'
                                        value='SELL'/>
                                    매각희망
                                    <TooltipPop contents="매각희망기업으로 등록시 씨오파트너 M&A센터를 통해 고객사 미팅을 진행하고, M&A중개계약 체결 후 잠재 인수자를 찾아드립니다." eventStop={false} />
                                </label>
                                <label className={cx('radio-btn')}>
                                    <Field
                                        type='radio'
                                        name='companyType'
                                        value='ACQUIRE'/>
                                    인수희망
                                     <TooltipPop contents="인수희망기업으로 등록시 씨오파트너 M&A 중개플랫폼을 통해 조건매칭 후보 매각기업을 추천받고, 인수중개를 진행할 수 있습니다." eventStop={false} />
                                </label>
                            </div>
                            {
                                values.companyType === 'NONE' && <ConnectChannelTalk btnText='M&A 중개 문의'>
                                    <p className={cx('title')}><span>M&A 니즈</span>를 가지는 고객사의 정보를 등록해 주세요.</p>
                                    <p className={cx('desc')}><span>검토가능기업, 매각희망기업, 인수희망기업</span>으로 등록시, 씨오파트너 M&A센터에서<br/>씨오파트너 회원에게 직접 연락드리고, M&A중개 절차를 안내해드립니다.</p>
                                </ConnectChannelTalk>
                            }
                            {
                                (values.companyType === 'CHECK' || values.companyType === 'SELL') && <CompanyTypeForm data={data} info={values.brokerage?.bizAreaInfo ?? []} perform={values.perform ?? perform} />
                            }
                            {
                                values.companyType === 'ACQUIRE' && <AcquirerInfoForm />
                                // <ConnectChannelTalk btnText='M&A 중개 문의'>
                                //     <p className={cx('title')}><span>기업인수 니즈</span>를 가진 고객사가 있다면 씨오파트너 M&A센터로 문의주세요.</p>
                                //     <p className={cx('desc')}>씨오파트너 M&A중개 플랫폼에 인수희망기업으로 등록 후<br/>조건매칭 후보 매각기업을 추천받고, 인수 중개를 진행할 수 있습니다.</p>
                                // </ConnectChannelTalk>
                            }
                        </div>
                    </Form>
                </div>
            }
        </Formik>
    );
}

export default MnAInfo;