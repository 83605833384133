import React, {useEffect, useRef, useState} from 'react';
import {usePdfComponents} from '../pdf-hooks';
import TitleBox from '../../../components/title-box';
import styles from './financial-info.module.scss';
import classNames from 'classnames/bind';
import {asyncCall} from '../../../util/util';
import {SelectItem} from '../../../model/types';
import {PrFrStat} from '../../../model/customer/dto/financial-statement';
import {CustomerDetail} from '../../../model/customer/dto/customer-detail';
import {useInjection} from 'inversify-react';
import {DialogManager} from '../../../components/dialog-manager';
import {useCredit} from '../../../model/customer/credit-hook';
import {CustomerApi} from '../../../model/customer/customer-api';
import {useMountedState} from 'react-use';
import {AuthStore} from '../../../model/auth/auth-store';
import {FsCodeDto} from "../../../model/customer/dto/fs-code-dto";
import EditFinancialInfoV2 from "../../customer/edit-financial-info";
import BasicSelectBox from "../../../components/basic-select-box";
import {toast} from "react-toastify";
import LoadingDialog from "../../../components/loading-dialog";
import {CustomerDetailTab} from "../../../data/const_list";
import {useNavigate} from "react-router-dom";
import {CrVer, Customer, FsLoadType, LoadType} from "../../../model/customer/dto/customer";
import {FsPdfDownload} from "../../detail-common";
import {SummaryReport} from "../../../model/inquiry/dto/summary-report";
import LottieComp from "../../../components/lottie-comp";
import {FsTableV2} from "./fs-table-v2";
import {calcFsStatEditYear} from "../report-util";
import {strSaved} from "../../../data/strings";

const cx = classNames.bind(styles);

export interface YearSelectItem extends SelectItem {
    update?: boolean;
}

interface FinancialInfoPropsV2 {
    summary: SummaryReport;
    customer: Customer;
    customerId?: string;
    data: PrFrStat[];

    bsTbl: FsCodeDto[];

    isTbl: FsCodeDto[];

    fsYear?: number;
    secondTab?: string;
    setData?: React.Dispatch<React.SetStateAction<CustomerDetail>>;
    edit?: boolean;
    v?: CrVer;
    loadType: LoadType;
    fsLoadType: FsLoadType;
    ownerHometaxData?: string;
}

const FinancialInfo = ({
                           summary,
                           customer,
                           customerId,
                           data,
                           bsTbl,
                           isTbl,
                           fsYear,
                           secondTab,
                           setData,
                           edit,
                           v,
                           loadType,
                           fsLoadType,
                           ownerHometaxData
                       }: FinancialInfoPropsV2) => {
    const {View, isPdf} = usePdfComponents();
    const [openEditPage, setOpenEditPage] = useState(edit);
    const dialogManager = useInjection(DialogManager);
    const [checkCredit, getCredit] = useCredit();
    const thisYear = (new Date()).getFullYear();


    const selectEditYear = useRef('');
    const lastEditYear = useRef(0);
    const navigate = !isPdf ? useNavigate!() : null;

    const editFsYears = calcFsStatEditYear();

    const showEdit = !editFsYears.every((ey => data?.findIndex((fr) => fr.refDt === ey) >= 0))
        || (data?.some((it) => it.temp || it.manual) ?? true);

    // 임시가 이닌 년도를 먼저 찾자
    const lastAutoData = data?.find(it => !it.temp);           // 자동 데이터 인덱스
    const existThisYearFr = data?.some(it => it.refDt === thisYear);           // 자동 데이터 인덱스

    // 선택 년도 리스트
    const selectList: YearSelectItem[] = data?.map((it, i) => ({id: it.refDt.toString(), title: it.refDt.toString()})) ?? [];

    const isInvalidFsYear = data?.some((it) => it.refDt >= (fsYear ?? 0));


    const firstSelectYear = selectList?.length >= 0 ? (lastAutoData ? lastAutoData.refDt : (selectList.length > 0 ? selectList[0].id : undefined)) : undefined;

    // 새로운 년도 존재
    const isUpdateFs = v === "MAIN" && loadType === "MS" && !isInvalidFsYear && !!(fsYear && selectList.findIndex(it => it.id === fsYear.toString()) === -1);

    const [selectYear, setSelectYear] = useState<string | undefined>(firstSelectYear);
    const authStore = useInjection(AuthStore);
    const customerApi = useInjection(CustomerApi);
    const isMounted = useMountedState();
    const [tab, setTab] = useState(secondTab !== 'isTbl');
    const fsLoadTimerRef = useRef<any>(null);

    useEffect(() => {
        if (!openEditPage && lastEditYear.current > 0) {
            const lastYear = lastEditYear.current;
            lastEditYear.current = 0;
            const findIdx = selectList.findIndex((s) => parseInt(s.id) == lastYear);

            if (findIdx >= 0) {
                setSelectYear(lastYear.toString());
            }
        } else if (!openEditPage && lastEditYear.current == -1) {
            lastEditYear.current = 0;
            setSelectYear(selectList[0].id);
        }
    }, [openEditPage]);

    const loadFsType = () => {
        fsLoadTimerRef.current = setTimeout(async () => {
            const loadResult = await customerApi.fsLoadResult(customerId!);

            if (loadResult.val?.fsLoadType === "START") {
                loadFsType();
            } else if (loadResult.val?.fsLoadType === "LOAD") {
                if (loadResult.val!.customer!.fss && loadResult.val!.customer!.fss.length > 0) {
                    setSelectYear(loadResult.val!.customer!.fss[0].refDt.toString());
                }
                setData!(loadResult.val!.customer!);
            }
        }, 2000);
    }

    const saveExcel = async () => {
        const loadResult = await customerApi.excelFsStat(customerId!);

        if (loadResult.success) {
            window.location.href = customerApi.makeDownloadFile(loadResult.data!);
        }
    }

    useEffect(() => {
        if (fsLoadType === "START") {
            loadFsType();
        }
    }, [fsLoadType]);

    useEffect(() => {
        return () => {
            if (fsLoadTimerRef.current) {
                clearTimeout(fsLoadTimerRef.current);
            }
        };
    }, []);

    const curData = selectYear ? data?.find((d) => d.refDt.toString() == selectYear) : undefined;

    const openEdit = () => {
        selectEditYear.current = selectYear as "";
        setOpenEditPage(true);
    }

    const updateWorkFsYear = (payId?: string) => {
        const key = dialogManager.open(LoadingDialog, {
            aniW: '10.0rem',
            aniH: '10.0rem',
            jsonFile: 'loading',
            title: '잠시만 기다려 주세요.'
        });

        asyncCall(customerApi.updateFinancialStmtV3, [customerId!, payId], result => {
            authStore.setCredit(result.credit!);
            dialogManager.close(key);
            toast.success("업데이트가 완료되었습니다.");

            // 고객 이동
            // navigate?.('/customer/customerState', {
            //     state: {
            //         customerId: result.customerId!,
            //         tab: CustomerDetailTab.Financial
            //     }
            // });

            navigate?.(`/customer/customerState/${result.customerId!}/${CustomerDetailTab.Financial}`);
        }, isMounted, (e) => {
            dialogManager.close(key);
        });
    }

    const updateFsYear = () => {
        const year = fsYear!;
        dialogManager.confirm(
            year + '년 재무제표 정보로 업데이트 하시겠습니까?',
            '',
            () => {
                updateWorkFsYear("");
            }, '확인'
        );
    }

    const updateLastYear = (lastYear: number) => {
        lastEditYear.current = lastYear;
        setOpenEditPage(false);
    }

    const applyHomeTaxData = () => {
        if (selectYear) {
            dialogManager.confirm('', '', () => {
                asyncCall(customerApi.updateFsHometax, [customerId!, ownerHometaxData!, Number(selectYear!)], (result) => {
                    const fsData = result as PrFrStat;
                    console.log(fsData, setData);
                    setData?.(data => ({...data, fss: data.fss?.map((fs) => (fs.refDt === fsData.refDt) ? {...fs, ...fsData} : fs)}));
                    toast.success(strSaved);
                }, isMounted, (errorMsg) => {
                    toast.error(errorMsg);
                });
            }, '적용하기', null, `홈택스 재무제표 정보가 있습니다.\n${selectYear}년 기준 재무정보에 적용하시겠습니까?`, '취소');
        }
    }

    const initThisYearFrStat = () => {
        dialogManager.confirm(
            thisYear + '년 추정재무제표를 추가하시겠습니까?',
            `수정 버튼 클릭 후 수동으로 반영해 주세요.`,
            async () => {

                const result = await customerApi.makeFrStatV2(customerId ?? "", thisYear);

                if (result.success) {
                    const loadResult = await customerApi.fsLoadResult(customerId!);

                    if (loadResult.val!.customer!.fss && loadResult.val!.customer!.fss.length > 0) {
                        setSelectYear(loadResult.val!.customer!.fss[0].refDt.toString());
                    }
                    setData!(loadResult.val!.customer!);
                }
            }, '확인'
        );
    }

    const deleteThisYearFrStat = () => {
        dialogManager.confirm(
            thisYear + '년 재무제표를 삭제하시겠습니까?',
            `삭제후 최근 결산연도 기준으로 복원됩니다.`,
            async () => {

                const result = await customerApi.deleteFinancialStmt(customerId ?? "", thisYear);

                if (result.success) {
                    const loadResult = await customerApi.fsLoadResult(customerId!);

                    if (loadResult.val!.customer!.fss && loadResult.val!.customer!.fss.length > 0) {
                        setSelectYear(loadResult.val!.customer!.fss[0].refDt.toString());
                    }
                    setData!(loadResult.val!.customer!);
                }


            }, '확인'
        );
    }

    const bs = curData?.bss;
    const is = curData?.iss;

    return <>
        {fsLoadType === "LOAD" && <>

            {!isPdf && customerId && setData && <>
                <TitleBox title='재무 정보' paddingTop='4.0rem'>
                    <div className={cx('title-box-right')}>
                        {
                            ownerHometaxData &&
                            <p className={cx('update-info')} onClick={applyHomeTaxData}>홈택스 정보 적용</p>
                        }

                        {isUpdateFs && <p className={cx('update-info')} onClick={updateFsYear}>NICE 업데이트</p>}

                        {existThisYearFr ?
                            <button
                                className={cx('update-info', 'gray')}
                                type='button'
                                onClick={() => deleteThisYearFrStat()
                                }>
                                {thisYear}년 삭제
                            </button> : <button
                                className={cx('update-info', 'sky')}
                                type='button'
                                onClick={() => initThisYearFrStat()
                                }>
                                {thisYear}년 추가
                            </button>
                        }

                        {/*<p className={cx('select-box-title')}>결산연도</p>*/}
                        <BasicSelectBox
                            selectList={selectList}
                            selected={selectYear}
                            inputWidth='4.6rem' unit='년'
                            onChange={id => {
                                setSelectYear(id as string);
                            }}
                        />

                        {showEdit &&
                            <button className='edit-btn' onClick={openEdit}><i></i><span>수정</span></button>}

                        {openEditPage && customerId && setData &&
                            <EditFinancialInfoV2 customerId={customerId} setData={setData}
                                                 initYear={selectEditYear.current}
                                                 onClose={updateLastYear}
                                                 enpTyp={customer.enpTyp ?? "1"}
                            />}

                    </div>
                </TitleBox>
                <div className={cx('tab-print-line')}>
                    <div className={cx('tab-area')}>
                        <button type='button' className={cx(tab && 'on')} onClick={() => setTab(true)}>재무상태표
                        </button>
                        <button type='button' className={cx(!tab && 'on')} onClick={() => setTab(false)}>손익계산서
                        </button>
                    </div>

                    <div className={cx('button-area')}>
                        <button type='button' className={cx('excel-btn')} onClick={() => saveExcel()}><i></i>재무제표 엑셀
                        </button>

                        <FsPdfDownload data={summary} fs={data} bsTbl={bsTbl} isTbl={isTbl} fsLoadType={fsLoadType} customer={customer}
                                       className={cx('summary-report')}/>
                    </div>
                </div>
            </>}

            {bs &&
                <>
                    {isPdf ?
                        <FsTableV2 title='재무상태표' isBS={true} data={bs} fsTbl={bsTbl}/>
                        : tab && <FsTableV2 title='재무상태표' isBS={true} data={bs} fsTbl={bsTbl}/>
                    }
                </>
            }

            {isPdf && <View break={true}/>}

            {is && <>
                {isPdf ?
                    <FsTableV2 title='손익계산서' isBS={false} data={is} fsTbl={isTbl}/>
                    : !tab && <FsTableV2 title='손익계산서' isBS={false} data={is} fsTbl={isTbl}/>
                }
            </>
            }
        </>}

        {fsLoadType === "START" && <>
            <div className={cx('loading')}>
                <LottieComp width='10rem' height='10rem' jsonFile='loading'/>
                <p className={cx('sub-title')}>잠시만 기다려 주세요.</p>
            </div>
        </>}

    </>;
};

export default FinancialInfo;